<template>
  <div class="home-one">
    <Header />
    <!-- End Header Section -->

    <Slider />
    <!-- End Slider Section -->

    <div class="shane_tm_section" id="about">
      <div class="shane_tm_about pb-0">
        <div class="container">
          <div class="shane_tm_title">
            <div class="title_flex">
              <div class="mb-md-4 mb-lg-5">
                <span>신입생모집</span>
                <h3>2024학년도 세움기독학교 신입생을 모집합니다.</h3>
              </div>
            </div>
          </div>

          <div class="text-center">
            <a href="/files/seumschool_admission_2024.hwp"><button class="btn btn-outline-info">입학원서 다운로드</button></a>
            <div class="mt-3"><img src="@/assets/img/main/poster_2024.jpg"></div>
          </div>

<!--          <About />-->
          <div class="btn-qna"><a href="#qna"><button class="btn btn-info">문의하기</button></a></div>
        </div>
        <!--  End container -->
      </div>
    </div>
    <!-- End About Section -->
<!--    <div class="shane_tm_section" id="portfolio">-->
<!--      <div class="shane_tm_portfolio">-->
<!--        <Portfolio />-->
<!--      </div>-->
<!--    </div>-->
    <!-- End Portfolio Section -->

<!--    <Skills />-->
    <!-- End Skills Section -->

<!--    <Video />-->
    <!-- End Video Section -->

<!--    <div class="shane_tm_section">-->
<!--      <div class="shane_tm_partners">-->
<!--        <div class="container">-->
<!--          <div class="partners_inner">-->
<!--            <Brand />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- End shane_tm_partners -->

<!--    <div class="shane_tm_section">-->
<!--      <div class="shane_tm_testimonials">-->
<!--        <div class="container">-->
<!--          <div class="testimonials_inner">-->
<!--            <div class="left">-->
<!--              <div class="shane_tm_title">-->
<!--                <span>Testimonials</span>-->
<!--                <h3>What clients say about my portfolio template</h3>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="right">-->
<!--              <Testimonial />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- End  shane_tm_testimonials -->

<!--    <div class="shane_tm_section" id="news">-->
<!--      <div class="shane_tm_news">-->
<!--        <div class="container">-->
<!--          <div class="shane_tm_title">-->
<!--            <span>News</span>-->
<!--            <h3>Latest News</h3>-->
<!--          </div>-->
<!--          &lt;!&ndash; End shane_tm_title &ndash;&gt;-->
<!--          <News />-->
<!--          &lt;!&ndash; End news  &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- End  news -->
    <!-- Contact form-->
    <div class="shane_tm_section" id="qna">
      <div class="shane_tm_about">
        <div class="container">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScIC1HRrENzVDOzc9SfvvsYTRXzo0oZP9Ip_fV0CyFh_WNiyw/viewform?embedded=true" width="100%" height="880" scrolling="no" frameborder="0" marginheight="0" marginwidth="0" style="overflow-y: hidden;">로드 중…</iframe>
          <!--          <h3 class="h3-big">Get in Touch</h3>-->
        </div>
      </div>

    </div>


<!--    <CallToActions />-->
    <!-- End  call to actions -->

    <!-- 찾아오시는 길 -->
    <div class="shane_tm_section" id="contact">
      <div class="container">
        <div class="shane_tm_title">
          <div class="title_flex">
            <div class="mb-md-4 mb-lg-5">
              <span>연락처</span>
              <h3>찾아오시는 길</h3>
            </div>
          </div>
        </div>

        <div class="row row-30 row-xl-55 justify-content-between align-items-center">
          <div class="col">
            <kakao-map></kakao-map>

          </div>
        </div>
        <div class="row row-30 row-xl-55 justify-content-between align-items-center">
          <div class="col-md-12">
            <!-- Blurb info-->
            <div class="blurb blurb-info my-4">
              <div class="blurb-header">
                <font-awesome-icon :icon="['far', 'map']" class="font-icon" />
                <div class="blurb-title h4">주소</div>
              </div>
              <div class="blurb-embed">
                <h5>(13113) 경기도 양주시 고덕로 139번길 36-6 (덕계동) 세움교회</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-30 row-xl-55 justify-content-between align-items-center mb-5">
          <div class="col-md-6">
            <!-- Blurb info-->
            <div class="blurb blurb-info text-dark mb-lg-0 mb-3">
              <div class="blurb-header">
                <font-awesome-icon :icon="['fa', 'mobile-alt']" class="font-icon" />
                <div class="blurb-title h4">연락처</div>
              </div>
              <div class="blurb-embed"><h5><a class="link link-inherit big2" href="tel:031-865-0091">031.865.0091</a></h5>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Blurb info-->
            <div class="blurb blurb-info">
              <div class="blurb-header">
                <font-awesome-icon :icon="['far', 'envelope']" class="font-icon" />
                <div class="blurb-title h4">E-Mail</div>
              </div>
              <div class="blurb-embed">
                <h5><a href="mailto:seumch.org@gmail.com">seumch.org@gmail.com</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- End  footer  -->
  </div>
</template>

<script>
import Header from "../../components/Header";
import Slider from "../../components/slider/Slider";
// import About from "../../components/about/About";
// import Portfolio from "../../components/portfolio/Portfolio";
// import Skills from "../../components/skills/Skills";
// import Video from "../../components/video/Video";
// import Brand from "../../components/Brand";
// import Testimonial from "../../components/testimonial/Testimonial.vue";
// import News from "../../components/news/News.vue";
// import CallToActions from "../../components/calltoactions/CallToActions.vue";
import Footer from "../../components/Footer.vue";
import KakaoMap from "../../components/KakaoMap";
export default {
  components: {
    KakaoMap,
    Header,
    Slider,
    // About,
    // Portfolio,
    // Skills,
    // Video,
    // Brand,
    // Testimonial,
    // News,
    // CallToActions,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
