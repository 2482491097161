<template>
  <div class="shane_tm_hero" id="home" data-style="one">
    <img :src="sliderHero">
<!--    <div-->
<!--        class="image"-->
<!--        :style="{ backgroundImage: 'url(' + sliderHero + ')' }"-->
<!--    ></div>-->
    <div class="background">

    </div>
    <!-- End .background -->

<!--    <div class="container">-->
<!--      <div class="content">-->
<!--        <div class="name_wrap">-->
<!--          <h3><span>Ave</span> Brook<span class="overlay_effect"></span></h3>-->
<!--        </div>-->
<!--        &lt;!&ndash; End title &ndash;&gt;-->

<!--        <div class="job_wrap">-->
<!--          <span class="job">-->
<!--            Independent Web Developer-->
<!--            <span class="overlay_effect"></span>-->
<!--          </span>-->
<!--        </div>-->
<!--        &lt;!&ndash; End designation &ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; End content &ndash;&gt;-->

<!--      <div class="shane_tm_down loaded">-->
<!--        <div class="line_wrapper">-->
<!--          <div class="line"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- End .container -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliderHero: require("@/assets/img/slider/08.jpg"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
