<template>
  <div>
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  name: "KakaoMap",
  data() {
    return {
      map: null,
      mapGps: {
        lat: 37.8088432881213,
        lng: 127.060177175597
      },
      markerPositions1: [
        [37.8088432881213, 127.060177175597]
      ],
    }
  },
  mounted() {
    if (window.kakao && window.kakao.maps) {
      this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
          "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=e7ee2dd5fa4aec11511f92fec62f64e0";
      document.head.appendChild(script);
    }
  },
  methods: {
    initMap() {
      console.log(['initMap']);
      const container = document.getElementById("map");
      const options = {
        center: new kakao.maps.LatLng(this.mapGps.lat, this.mapGps.lng),
        level: 1,
      };
      this.map = new kakao.maps.Map(container, options);
    },
  }
}
</script>

<style scoped>

</style>
